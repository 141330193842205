import './test-input-page.css'

import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  message,
} from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStudentsLazyQuery } from '@alpha/core'
import { CloseOutlined } from '@ant-design/icons'
import { ColumnsType, SortOrder } from 'antd/lib/table/interface'
import { useAlphaStore } from '~/context'
import { useIsOnlineStore } from '~/context/online'
import { useLoadingPercent } from '~/hooks/useLoadingPercent'
import {
  ageFromSchool,
  checkIfIsValidRank,
  parser,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '~/utils'
import Api from '~/utils/api'
import { calculateBmi, calculateBmiByExistWeightHeight } from '~/utils/bmi'
import {
  currentYear,
  pageSize as defaultPageSize,
  globalInputKeys,
} from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { awardTestKeysHasShuttleRunOnly } from '~/utils/test-const'
import { checkIfIsAllTestResInputted } from '~/utils/test-result'
import { TStudent } from '../../utils/types/api/res'
import { Dashboard } from '../layout/Dashboard'
import CircularProgressLoading from '../shared/CircularProgressLoading'
import { incorrectValue } from '../shared/IncorrectValue'
import {
  InputConfig,
  getInputListOfTestRes,
  getInputRulesForTestRes,
  getNoEnduranceRunInputList,
  heightRules,
  listInputEdit,
  weightRules,
} from './test-input/constants'
import { checkChange } from './test-input/utils/change'
import {
  convertValue,
  convertValueForReqApi,
  setFormAll,
} from './test-input/utils/form'

const { Option } = Select
const { useBreakpoint } = Grid

/**
 * 体力テスト結果の入力ページ
 *
 * Path: /test
 */
const TestInputPage = () => {
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  /**
   * Using at table.
   */
  const [filteredStudents, setFilteredStudents] = useState<TStudent[]>([])
  const [isNotMeasurable, setIsNotMeasurable] = useState({})
  const [filterGender, setFilterGender] = useState('')
  const [selectedStudent, setSelectedStudent] = useState<null | TStudent>(null)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [idModal, setIdModal] = useState('')
  const [isReadyToCallGetStudents, setIsReadyToCallGetStudents] =
    useState(false)

  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const [listIds, setListIds] = useState<string[]>([])

  const [formState, setFormState] = useState<'unchanged' | 'change'>(
    'unchanged',
  )

  const [form] = Form.useForm()

  const [getStudents, { data: studentData, loading: loadingStudent }] =
    useStudentsLazyQuery({
      fetchPolicy: 'network-only',
    })

  const loadingPercent = useLoadingPercent(160)

  const { isOnline } = useIsOnlineStore()

  // loadingStudents
  useEffect(() => {
    if (!school || !isReadyToCallGetStudents) return

    getStudents({
      variables: {
        input: {
          schoolId: school._id,
          testYear: currentYear,
          schoolClass,
          schoolGrade,
        },
      },
    })
  }, [school, getStudents, schoolGrade, schoolClass, isReadyToCallGetStudents])

  // setFilteredStudents
  useEffect(() => {
    if (!school) return

    let _filteredStudents: TStudent[]

    if (isOnline) {
      if (!studentData?.students?.data) {
        return
      }

      setTotal(studentData.students.count ?? 0)
      _filteredStudents = studentData.students.data as TStudent[]
    } else {
      if (!studentData?.students?.data) {
        setFilteredStudents([])
        setTotal(0)
        return
      }

      _filteredStudents = studentData.students.data.filter(
        (s) =>
          s.attributes.schoolClass === schoolClass &&
          s.attributes.schoolGrade === schoolGrade,
      ) as TStudent[]
    }

    if (filterGender !== '') {
      _filteredStudents = _filteredStudents.filter(
        (student) => student.attributes.gender === filterGender,
      )
    }

    const schoolCategoryCode = school.attributes.schoolCategoryCode

    _filteredStudents.forEach((student) => {
      if (!student?.attributes?.testResults) return

      const testResults = student.attributes.testResults

      const trIdx = testResults.findIndex((d) => d.testYear === currentYear)

      if (trIdx >= 0) {
        const age = ageFromSchool(
          schoolCategoryCode,
          student.attributes.schoolGrade as number,
        )

        const gender = student.attributes.gender

        if (gender) {
          const testRes = testResults[trIdx]

          // @ts-ignore
          student.attributes.testResults[trIdx] = reCalcTestResult(
            testRes,
            age,
            gender,
            hasEnduranceRun,
          )
        }
      }
    })

    setFilteredStudents(_filteredStudents)
  }, [
    school,
    studentData?.students?.data,
    studentData?.students?.count,
    filterGender,
    isOnline,
    schoolClass,
    schoolGrade,
  ])

  const onChangeGrade = useCallback((val: number) => {
    setSchoolGrade(val)
  }, [])

  const onChangeClass = useCallback((val: number) => {
    setSchoolClass(val)
  }, [])

  const onChangeGender = useCallback((val: string) => {
    setFilterGender(val)
  }, [])

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (formState !== 'unchanged') {
      window.addEventListener('beforeunload', handler)
      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
    return () => {}
  }, [formState])

  const hasEnduranceRun = schoolHasEnduranceTest(school?.attributes)

  const inputKeys = hasEnduranceRun
    ? globalInputKeys
    : awardTestKeysHasShuttleRunOnly

  const getInputList = () => {
    return hasEnduranceRun
      ? getInputListOfTestRes(t)
      : getNoEnduranceRunInputList(t)
  }

  const renderTest = (v, test) => {
    const defVal = incorrectValue
    if (!v || v.length === 0) return defVal
    const tr = v.find((d) => d.testYear === currentYear)
    if (!tr || tr.length === 0) return defVal

    switch (test) {
      case 'bending':
      case 'standingJump':
      case 'handballThrow': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (value === null || (!value && test !== 'sitUps')) return defVal
        return <div>{value}</div>
      }
      case 'grip':
      case 'sitUps':
      case 'sprintRun':
      case 'sideJump':
      case 'shuttleRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (value === null || (!value && test !== 'sitUps')) return defVal
        return <div>{value}</div>
      }
      // this is wrong (originally at least). runningTime is not a number. is the timeLabel (mm:ss)
      // runningTimeSeconds is the sum of minutes and seconds.
      case 'enduranceRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        if (tr.enduranceRun?.isNotMeasurable === true) return '無'
        const value =
          tr[test].runningTime && tr[test].runningTimeSeconds
            ? `${tr.enduranceRun?.runningTime}'${tr.enduranceRun?.runningTimeSeconds}''`
            : defVal
        if (!value) return defVal
        return <div>{value}</div>
      }
      case 'height':
      case 'weight': {
        const value = tr.sizeTest?.[test]
        if (!value || value.length === 0) return defVal
        return <div>{value}</div>
      }
      case 'bmi': {
        let value = ''
        if (tr?.sizeTest?.weight && tr.sizeTest.height) {
          value = calculateBmiByExistWeightHeight(tr.sizeTest)

          if (!value || value.length === 0) return defVal
        } else {
          return defVal
        }
        return <div>{Number(value)}</div>
      }
    }
  }

  const getTestResults = (student) => {
    return student.attributes.testResults?.find(
      (d) => d.testYear === currentYear,
    )
  }

  const sortTestResults = (a, b, key) => {
    let element1: undefined | number | string
    let element2: undefined | number | string

    switch (key) {
      case 'grip':
      case 'sitUps':
      case 'bending':
      case 'sprintRun':
      case 'sideJump':
      case 'standingJump':
      case 'shuttleRun':
      case 'handballThrow':
      case 'enduranceRun': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.[key]?.value
        element2 = b_tr?.[key]?.value
        break
      }
      case 'rank':
      case 'points': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.[key]
        element2 = b_tr?.[key]
        break
      }
      case 'height':
      case 'weight': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.sizeTest?.[key]
        element2 = b_tr?.sizeTest?.[key]
        break
      }
      case 'bmi': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = calculateBmi(a_tr?.sizeTest)
        element2 = calculateBmi(b_tr?.sizeTest)
        break
      }
      case 'name': {
        element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
        element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
        break
      }
      default: {
        element1 = a?.[key]
        element2 = b?.[key]
      }
    }

    if (element1 == null && element2 == null) return 1
    if (element1 == null) return -1
    if (element2 == null) return 1
    return typeof element1 === 'number'
      ? element1 - (element2 as number)
      : element1.localeCompare(element2 as string)
  }

  let tableColumns = [
    {
      title: t('登録状況'),
      dataIndex: ['attributes', 'testResults'],
      key: 'status',
      fixed: 'left',
      width: 85,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        const status1 = checkIfIsAllTestResInputted(
          a?.attributes?.testResults,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )
        const status2 = checkIfIsAllTestResInputted(
          b?.attributes?.testResults,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )

        return status1 === status2 ? 0 : !status1 ? -1 : 1
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        const isAllInputted = checkIfIsAllTestResInputted(
          v,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )

        return (
          <div
            className="flex justify-center items-center"
            style={{ height: 22 }}
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
              style={{ backgroundColor: isAllInputted ? '#00944D' : '#CA4141' }}
            >
              {isAllInputted ? '済' : '未'}
            </div>
          </div>
        )
      },
    },
    {
      title: t('学年'),
      dataIndex: ['attributes', 'schoolGrade'],
      key: 'schoolGrade',
      fixed: 'left',
      width: isUsingJp ? 40 : 78,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('組'),
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      fixed: 'left',
      width: isUsingJp ? 40 : 78,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('番'),
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      key: 'schoolAttendanceNumber',
      fixed: 'left',
      width: isUsingJp ? 40 : 92,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('性別'),
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      fixed: 'left',
      width: 60,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        const gender1 = a?.attributes?.gender
        const gender2 = b?.attributes?.gender

        if (!gender1 && !gender2) return 1
        if (!gender1) return -1
        if (!gender2) return 1
        return gender1.localeCompare(gender2)
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => <div>{v ? (v === 'MALE' ? t('男') : t('女')) : '-'}</div>,
    },
    {
      title: t('名前'),
      dataIndex: ['attributes'],
      key: 'name',
      fixed: 'left',
      width: 100,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'name')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return (
          <div className="whitespace-normal">
            {t('フルネーム', {
              familyName: v.familyName,
              givenName: v.givenName,
            })}
          </div>
        )
      },
    },
    {
      title: t('生年月日'),
      dataIndex: ['attributes'],
      key: 'birthday',
      width: 138,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'birthday')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return (
          <div className="whitespace-nowrap">
            {v.yearBirth &&
              v.monthBirth &&
              v.dayBirth &&
              t('年月日', {
                year: v.yearBirth,
                month: t(`${v.monthBirth}月`),
                day: v.dayBirth,
              })}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: ['attributes'],
      key: 'actions',
      width: isUsingJp ? 58 : 74,
      render: (v, r) => {
        return (
          <div
            className={
              !v.testResults || v.testResults.length === 0
                ? 'bg-gray-160 -m-2 px-2'
                : ''
            }
          >
            <Button
              type="primary"
              className="ant-btn-xs text-10px test-result-details-btn"
              onClick={(e) => {
                e.preventDefault()
                if (!v.familyName) return

                editModal(r)
                setSelectedStudent(r)
                const tr = r.attributes.testResults.find(
                  (d) => d.testYear === currentYear,
                )

                const isNotMeasurable = {}
                getInputListOfTestRes(t).forEach((input) => {
                  isNotMeasurable[input.key] = tr?.[input.key]?.isNotMeasurable
                })
                setIsNotMeasurable(isNotMeasurable)
                setShowDetailModal(true)
                return false
              }}
              disabled={false}
            >
              {t('詳細')}
            </Button>
          </div>
        )
      },
    },
    {
      title: t('総得点'),
      dataIndex: ['attributes', 'testResults'],
      key: 'points',
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 70 : 128,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'points')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        const tr = v.find((d) => d.testYear === currentYear)

        return <div>{tr ? tr?.points : null}</div>
      },
    },
    {
      title: t('評価'),
      dataIndex: ['attributes', 'testResults'],
      key: 'rank',
      width: isUsingJp ? 60 : 128,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'rank')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        const tr = v.find((d) => d.testYear === currentYear)
        const status = checkIfIsAllTestResInputted(
          v,
          hasEnduranceRun,
          inputKeys,
          currentYear,
        )
        const showRanking = status || checkIfIsValidRank(tr, hasEnduranceRun)

        return <div>{showRanking ? tr?.rank : incorrectValue}</div>
      },
    },
    {
      title: t('身長'),
      dataIndex: [],
      key: 'height',
      width: 140,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'height')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v) => {
        return EditableCell(v?._id, 'height')
      },
    },
    {
      title: t('体重'),
      dataIndex: [],
      key: 'weight',
      width: 140,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'weight')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined) => {
        return EditableCell(v?._id, 'weight')
      },
    },
    {
      title: t('BMI'),
      dataIndex: ['attributes', 'testResults'],
      key: 'bmi',
      width: 60,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bmi')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined) => {
        return renderTest(v, 'bmi')
      },
    },
    {
      title: t('握力'),
      dataIndex: [],
      key: 'grip',
      width: isUsingJp ? 826 : 698,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'grip')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.grip?.isNotMeasurable
        return EditableCell(v?._id, 'grip', isDisabled)
      },
    },
    {
      title: t('上体起こし'),
      dataIndex: [],
      key: 'sitUps',
      width: isUsingJp ? 140 : 176,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sitUps')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.sitUps?.isNotMeasurable
        return EditableCell(v?._id, 'sitUps', isDisabled)
      },
    },
    {
      title: t('長座体前屈'),
      dataIndex: [],
      key: 'bending',
      width: 395,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bending')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.bending?.isNotMeasurable
        return EditableCell(v?._id, 'bending', isDisabled)
      },
    },
    {
      title: t('反復横跳び'),
      dataIndex: [],
      key: 'sideJump',
      width: 395,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sideJump')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.sideJump?.isNotMeasurable
        return EditableCell(v?._id, 'sideJump', isDisabled)
      },
    },
    {
      title: t('持久走'),
      dataIndex: [],
      key: 'enduranceRun',
      width: 300,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'enduranceRun')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.enduranceRun?.isNotMeasurable
        return EditableCell(v?._id, 'enduranceRun', isDisabled)
      },
    },
    {
      title: t('20mシャトルラン'),
      dataIndex: [],
      key: 'shuttleRun',
      width: isUsingJp ? 152 : 168,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'shuttleRun')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.shuttleRun?.isNotMeasurable
        return EditableCell(v?._id, 'shuttleRun', isDisabled)
      },
    },
    {
      title: t('50m走'),
      dataIndex: [],
      key: 'sprintRun',
      width: 140,
      editable: true,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sprintRun')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.sprintRun?.isNotMeasurable
        return EditableCell(v?._id, 'sprintRun', isDisabled)
      },
    },
    {
      title: t('立ち幅跳び'),
      dataIndex: [],
      key: 'standingJump',
      width: 395,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'standingJump')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.standingJump?.isNotMeasurable
        return EditableCell(v?._id, 'standingJump', isDisabled)
      },
    },
    {
      title: t('ボール投げ'),
      dataIndex: [],
      key: 'handballThrow',
      width: 395,
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'handballThrow')
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (v: { _id: string } | undefined, d) => {
        const isDisabled =
          d?.attributes?.testResults?.[0]?.handballThrow?.isNotMeasurable
        return EditableCell(v?._id, 'handballThrow', isDisabled)
      },
    },
  ]

  if (!hasEnduranceRun) {
    tableColumns = tableColumns.filter(
      (tableColumn) => tableColumn.key !== 'enduranceRun',
    )
  }

  const saveAll = async () => {
    if (!school) {
      message.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error(
        'when call updateStudentTestResult, error: school is not found',
      )
      return
    }

    setReloading(true)
    setTimeout(async () => {
      const dataSaveEdit: Record<string, string | number | {} | undefined>[] =
        []
      let checkValidate = false
      const dataEdit: (TStudent & { index: number })[] = []
      const arrayIds = listIds

      for (let i = 0; i < filteredStudents.length; i++) {
        const index = filteredStudents[i]?._id
          ? arrayIds.indexOf(filteredStudents[i]?._id)
          : -1
        if (index > -1) {
          arrayIds.splice(index, 1)
          dataEdit.push({ ...filteredStudents[i], index: i })
        }
      }
      dataEdit.forEach((test) => {
        const _id = test._id

        listInputEdit.forEach((input) => {
          const listValidate = formEdit.getFieldError([`${input}_${_id}`])
          if (listValidate.length) {
            checkValidate = true
          }
        })

        if (checkValidate) {
          return
        }

        const dataFormEdit = formEdit.getFieldsValue()

        const dataSave = {
          weight: dataFormEdit[`weight_${_id}`],
          height: dataFormEdit[`height_${_id}`],
          gripRight1: dataFormEdit[`gripRight1_${_id}`],
          gripRight2: dataFormEdit[`gripRight2_${_id}`],
          gripLeft1: dataFormEdit[`gripLeft1_${_id}`],
          gripLeft2: dataFormEdit[`gripLeft2_${_id}`],
          sitUps: dataFormEdit[`sitUps_${_id}`],
          bending1: dataFormEdit[`bending1_${_id}`],
          bending2: dataFormEdit[`bending2_${_id}`],
          sideJump1: dataFormEdit[`sideJump1_${_id}`],
          sideJump2: dataFormEdit[`sideJump2_${_id}`],
          shuttleRunCount: dataFormEdit[`shuttleRunCount_${_id}`],
          sprintRunSeconds: dataFormEdit[`sprintRunSeconds_${_id}`],
          standingJump1: dataFormEdit[`standingJump1_${_id}`],
          standingJump2: dataFormEdit[`standingJump2_${_id}`],
          handballThrow1: dataFormEdit[`handballThrow1_${_id}`],
          handballThrow2: dataFormEdit[`handballThrow2_${_id}`],
          runningTime: dataFormEdit[`runningTime_${_id}`],
          runningTimeSeconds: dataFormEdit[`runningTimeSeconds_${_id}`],
        }

        const check = checkChange(
          dataSave,
          typeof test?.index === 'number'
            ? filteredStudents?.[test?.index]?.attributes?.testResults?.[0]
            : null,
        )

        if (!check?.notChange) {
          const dataForm = setDataForm(
            dataSave,
            check,
            typeof test?.index === 'number' ? test?.index : -1,
          )

          dataForm[0].studentId = _id
          dataSaveEdit.push(dataForm[0])
        }
      })
      if (checkValidate) {
        setReloading(false)
        return
      }

      try {
        if (dataSaveEdit?.length > 0) {
          await Api.post(
            '/alpha/v1/school/updateStudentTestResult',
            dataSaveEdit,
          )

          setShowDetailModal(false)
          formEdit.resetFields()
          message.success(t('登録完了'))
        }

        await getStudents({
          variables: {
            input: {
              schoolId: school._id,
              testYear: currentYear,
              schoolClass,
              schoolGrade,
            },
          },
        })

        setFormState('unchanged')
        setListIds([])
      } catch (err) {
        message.error(`${t('エラーが発生しました。')} [${err}]`)
        console.error('when getStudents, error:', err)

        setReloading(false)
      }
      setReloading(false)
    }, 2)
  }

  const editModal = (record) => {
    const defVal = record?.attributes?.testResults
    const tr = defVal.find((d) => d.testYear === currentYear)
    setIdModal(defVal?.studentId)
    form.setFieldsValue({
      weight: tr?.sizeTest?.weight,
      height: tr?.sizeTest?.height,
      gripNotMeasurable: tr?.grip?.isNotMeasurable,
      gripMemo: tr?.grip?.memo,
      sitUpsNotMeasurable: tr?.sitUps?.isNotMeasurable,
      sitUpsMemo: tr?.sitUps?.memo,
      bendingNotMeasurable: tr?.bending?.isNotMeasurable,
      bendingMemo: tr?.bending?.memo,
      sideJumpNotMeasurable: tr?.sideJump?.isNotMeasurable,
      sideJumpMemo: tr?.sideJump?.memo,
      enduranceRunNotMeasurable: tr?.enduranceRun?.isNotMeasurable,
      enduranceRunMemo: tr?.enduranceRun?.memo,
      sprintRunNotMeasurable: tr?.sprintRun?.isNotMeasurable,
      sprintRunMemo: tr?.sprintRun?.memo,
      standingJumpNotMeasurable: tr?.standingJump?.isNotMeasurable,
      standingJumpMemo: tr?.standingJump?.memo,
      handballThrowNotMeasurable: tr?.handballThrow?.isNotMeasurable,
      handballThrowMemo: tr?.handballThrow?.memo,
      gripRight1: tr?.grip?.gripRight1,
      gripRight2: tr?.grip?.gripRight2,
      gripLeft1: tr?.grip?.gripLeft1,
      gripLeft2: tr?.grip?.gripLeft2,
      sitUps: tr?.sitUps?.sitUps,
      bending1: tr?.bending?.bending1,
      bending2: tr?.bending?.bending2,
      sideJump1: tr?.sideJump?.sideJump1,
      sideJump2: tr?.sideJump?.sideJump2,
      shuttleRunCount: tr?.shuttleRun?.shuttleRunCount,
      shuttleRunNotMeasurable: tr?.shuttleRun?.isNotMeasurable,
      sprintRunSeconds: tr?.sprintRun?.sprintRunSeconds,
      standingJump1: tr?.standingJump?.standingJump1,
      standingJump2: tr?.standingJump?.standingJump2,
      handballThrow1: tr?.handballThrow?.handballThrow1,
      handballThrow2: tr?.handballThrow?.handballThrow2,
      enduranceRun: tr?.enduranceRun?.value,
      runningTime: tr?.enduranceRun?.runningTime,
      runningTimeSeconds: tr?.enduranceRun?.runningTimeSeconds,
    })
  }
  const screens = useBreakpoint()

  const modalProps = {
    visible: showDetailModal,
    width: screens.xxl ? '70vw' : screens.lg ? '85vw' : '90vw',
    onCancel: () => {
      setShowDetailModal(false)
      form.resetFields()
      setIdModal('')
    },
    closeIcon: <CloseOutlined className="text-2xl color-blue-f" />,
    footer: null,
  }

  const renderInput = (input: InputConfig) => {
    const tabTitleDefaultWidth = isUsingJp ? 'w-16' : 'w-32'

    return (
      <div key={input?.key}>
        <Row>
          <div
            className={`h-5 ${
              input.tab_width ? input.tab_width : tabTitleDefaultWidth
            } bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black`}
          >
            {input.tab}
          </div>
        </Row>
        <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
          {input.items.map((item, index) => (
            <Col key={`${index}`} span={6}>
              <div className="flex relative">
                <Form.Item
                  className="mb-0-f "
                  name={item.name}
                  label={
                    item.label ? (
                      <span className="text-10px">{item.label}</span>
                    ) : null
                  }
                  rules={
                    input.measurable && isNotMeasurable[input.key] === true
                      ? []
                      : item.rules
                  }
                  validateTrigger={
                    input.measurable && isNotMeasurable[input.key] === true
                      ? []
                      : ['onSubmit', 'onChange']
                  }
                >
                  <InputNumber
                    size="small"
                    disabled={isNotMeasurable[input.key]}
                    parser={parser}
                    step="any"
                  />
                </Form.Item>
                {item.suffix && (
                  <div
                    className={`flex items-end absolute left-24 ${
                      item.label ? 'top-10' : 'top-4'
                    }`}
                  >
                    <span className="text-10px">{item.suffix}</span>
                  </div>
                )}
              </div>
            </Col>
          ))}

          {input.measurable && (
            <Col key="measurable" span={input.items.length >= 4 ? 24 : 6}>
              <Form.Item
                className="mb-0-f"
                style={
                  input.items.find((item) => !item.label) ||
                  input.items.length === 4
                    ? {}
                    : { position: 'relative', top: '50%' }
                }
                name={`${input.key}NotMeasurable`}
                valuePropName="checked"
              >
                <Checkbox
                  className="text-10px font-normal"
                  onChange={(event) => {
                    const newValue = {
                      ...isNotMeasurable,
                    }
                    newValue[input.key] = event.target.checked
                    setIsNotMeasurable(newValue)
                    form.validateFields()
                  }}
                >
                  {t('今年度測定なし')}
                </Checkbox>
              </Form.Item>
            </Col>
          )}

          {input.memoable && (
            <Col key="memo" span={24}>
              <label className="text-10px">{t('メモ')}</label>
              <Form.Item className="mb-0-f " name={`${input.key}Memo`}>
                <Input size="small" />
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
    )
  }

  const setDataForm = (
    values,
    check = {
      gripTest: true,
      sitUpsTest: true,
      bendingTest: true,
      sideJumpTest: true,
      enduranceRunTest: true,
      shuttleRunTest: true,
      sprintRunTest: true,
      standingJumpTest: true,
      handballThrowTest: true,
      sizeTest: true,
    },
    index: number,
  ) => {
    let oldData: null | any = null
    if (idModal) {
      for (let i = 0; i < filteredStudents.length; i++) {
        if (
          filteredStudents?.[i]?.attributes?.testResults?.[0]?.studentId ===
          idModal
        ) {
          oldData = filteredStudents?.[i]?.attributes?.testResults?.[0]
        }
      }
    } else {
      oldData =
        typeof index === 'number' && index >= 0
          ? filteredStudents?.[index]?.attributes?.testResults?.[0]
          : null
    }

    const {
      gripTest,
      sitUpsTest,
      bendingTest,
      sideJumpTest,
      enduranceRunTest,
      shuttleRunTest,
      sprintRunTest,
      standingJumpTest,
      handballThrowTest,
      sizeTest,
    } = check

    return [
      {
        testYear: currentYear,
        studentId: selectedStudent?._id,
        gripTest: gripTest
          ? {
              grip: {
                gripRight1: convertValue(
                  values.gripRight1,
                  oldData?.grip?.gripRight1,
                ),
                gripRight2: convertValue(
                  values.gripRight2,
                  oldData?.grip?.gripRight2,
                ),
                gripLeft1: convertValue(
                  values.gripLeft1,
                  oldData?.grip?.gripLeft1,
                ),
                gripLeft2: convertValue(
                  values.gripLeft2,
                  oldData?.grip?.gripLeft2,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        sitUpsTest: sitUpsTest
          ? {
              sitUps: {
                sitUps: convertValue(values.sitUps, oldData?.sitUps?.sitUps),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        bendingTest: bendingTest
          ? {
              bending: {
                bending1: convertValue(
                  values.bending1,
                  oldData?.bending?.bending1,
                ),
                bending2: convertValue(
                  values.bending2,
                  oldData?.bending?.bending2,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        sideJumpTest: sideJumpTest
          ? {
              sideJump: {
                sideJump1: convertValue(
                  values.sideJump1,
                  oldData?.sideJump?.sideJump1,
                ),
                sideJump2: convertValue(
                  values.sideJump2,
                  oldData?.sideJump?.sideJump2,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        enduranceRunTest: enduranceRunTest
          ? {
              enduranceRun: {
                runningTime: convertValue(
                  values.runningTime,
                  oldData?.enduranceRun?.runningTime,
                ),
                runningTimeSeconds: convertValue(
                  values.runningTimeSeconds,
                  oldData?.enduranceRun?.runningTimeSeconds,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        shuttleRunTest: shuttleRunTest
          ? {
              shuttleRun: {
                shuttleRunCount: convertValue(
                  values.shuttleRunCount,
                  oldData?.shuttleRun?.shuttleRunCount,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        sprintRunTest: sprintRunTest
          ? {
              sprintRun: {
                sprintRunSeconds: convertValue(
                  values.sprintRunSeconds,
                  oldData?.sprintRun?.sprintRunSeconds,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        standingJumpTest: standingJumpTest
          ? {
              standingJump: {
                standingJump1: convertValue(
                  values.standingJump1,
                  oldData?.standingJump?.standingJump1,
                ),
                standingJump2: convertValue(
                  values.standingJump2,
                  oldData?.standingJump?.standingJump2,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        handballThrowTest: handballThrowTest
          ? {
              handballThrow: {
                handballThrow1: convertValue(
                  values.handballThrow1,
                  oldData?.handballThrow?.handballThrow1,
                ),
                handballThrow2: convertValue(
                  values.handballThrow2,
                  oldData?.handballThrow?.handballThrow2,
                ),
                isNotMeasurable: undefined,
                memo: undefined,
              },
            }
          : undefined,
        sizeTest: sizeTest
          ? {
              weight: convertValue(values.weight, oldData?.sizeTest?.weight),
              height: convertValue(values.height, oldData?.sizeTest?.height),
            }
          : undefined,
      },
    ]
  }

  const onFinish = async (values) => {
    if (!school) {
      message.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('when call school/result, error: school is not found')
      return
    }

    // selectedStudent is must be TStudent, because this function is called
    // when selectedStudent is not null.
    const updatedTestResOfStudent = convertValueForReqApi(
      values,
      isNotMeasurable,
      idModal,
      filteredStudents,
      selectedStudent as TStudent,
    )

    try {
      setReloading(true)
      setLoading(true)
      const res = await Api.post(
        '/alpha/v1/school/result',
        updatedTestResOfStudent,
      )
      setShowDetailModal(false)
      form.resetFields()

      if (res.status === 500) {
        message.error(`${t('エラーが発生しました。')} [${res.data.error}]`)
        return
      }

      message.success(t('登録完了'))

      await getStudents({
        variables: {
          input: {
            schoolId: school._id,
            testYear: currentYear,
            schoolClass,
            schoolGrade,
          },
        },
      })
      setFormState('unchanged')
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
      console.error('when call school/result, error:', err)
    } finally {
      setReloading(false)
      setLoading(false)
      setIdModal('')
    }
  }

  const totalCompletion = (filteredStudents ?? []).filter((result) =>
    checkIfIsAllTestResInputted(
      result?.attributes?.testResults as Record<string, any>[] | undefined,
      hasEnduranceRun,
      inputKeys,
      currentYear,
    ),
  ).length

  const EditableCell = (record, dataIndex, isDisabled?: boolean) => {
    let form: null | JSX.Element = null
    switch (dataIndex) {
      case 'height':
        const height = dataFormItem(dataIndex, record, isDisabled)
        form = (
          <>
            <div className="flex items-center relative mr-4">
              {height}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
          </>
        )
        break

      case 'sitUps':
        const sitUps = dataFormItem(dataIndex, record, isDisabled)
        form = (
          <>
            <div className="flex items-center relative mr-4">
              {sitUps}
              <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
            </div>
          </>
        )
        break
      case 'weight':
        const weight = dataFormItem(dataIndex, record, isDisabled)
        form = (
          <>
            <div className="flex items-center relative mr-4">
              {weight}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>
          </>
        )
        break
      case 'grip':
        const gripRight1 = dataFormItem('gripRight1', record, isDisabled)
        const gripRight2 = dataFormItem('gripRight2', record, isDisabled)
        const gripLeft1 = dataFormItem('gripLeft1', record, isDisabled)
        const gripLeft2 = dataFormItem('gripLeft2', record, isDisabled)

        form = isUsingJp ? (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">右 (1回目)</span>
              {gripRight1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">右 (2回目)</span>
              {gripRight2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">左 (1回目)</span>
              {gripLeft1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">左 (2回目)</span>
              {gripLeft2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('右')}
                <br />
                {t('1回目_test')}
              </span>
              {gripRight1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('右')}
                <br />
                {t('2回目_test')}
              </span>
              {gripRight2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('左')}
                <br />
                {t('1回目_test')}
              </span>
              {gripLeft1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>

            <div className="flex relative mr-4 text-xs">
              <span className="mr-0 text-title text-title-en">
                {t('左')}
                <br />
                {t('2回目_test')}
              </span>
              {gripLeft2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>
          </>
        )
        break

      case 'bending':
        const bending1 = dataFormItem('bending1', record, isDisabled)
        const bending2 = dataFormItem('bending2', record, isDisabled)

        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {bending1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {bending2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
          </>
        )
        break

      case 'sideJump':
        const sideJump1 = dataFormItem('sideJump1', record, isDisabled)
        const sideJump2 = dataFormItem('sideJump2', record, isDisabled)

        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {sideJump1}
              <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {sideJump2}
              <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
            </div>
          </>
        )
        break

      case 'standingJump':
        const standingJump1 = dataFormItem('standingJump1', record, isDisabled)
        const standingJump2 = dataFormItem('standingJump2', record, isDisabled)
        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {standingJump1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {standingJump2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
          </>
        )
        break

      case 'handballThrow':
        const handballThrow1 = dataFormItem(
          'handballThrow1',
          record,
          isDisabled,
        )
        const handballThrow2 = dataFormItem(
          'handballThrow2',
          record,
          isDisabled,
        )

        form = (
          <>
            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('1回目_test')}</span>
              {handballThrow1}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">m</span>
              </div>
            </div>

            <div className="flex relative mr-4">
              <span className="mr-0 text-title">{t('2回目_test')}</span>
              {handballThrow2}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">m</span>
              </div>
            </div>
          </>
        )
        break

      case 'enduranceRun':
        const runningTime = dataFormItem('runningTime', record, isDisabled)
        const runningTimeSeconds = dataFormItem(
          'runningTimeSeconds',
          record,
          isDisabled,
        )

        form = (
          <>
            <div className="flex items-center relative mr-4">
              {runningTime}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">{t('分')}</span>
              </div>
            </div>
            <div className="flex items-center relative mr-4">
              {runningTimeSeconds}
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">{t('秒')}</span>
              </div>
            </div>
          </>
        )
        break

      case 'shuttleRun':
        const shuttleRunCount = dataFormItem(
          'shuttleRunCount',
          record,
          isDisabled,
        )

        form = (
          <>
            <div className="flex items-center relative mr-4">
              {shuttleRunCount}
              <div className="flex items-end -ml-2 text-10px">{t('回')}</div>
            </div>
          </>
        )
        break

      case 'sprintRun':
        const sprintRunSeconds = dataFormItem(
          'sprintRunSeconds',
          record,
          isDisabled,
        )

        form = (
          <>
            <div className="flex items-center relative mr-4">
              {sprintRunSeconds}
              <div
                className={`flex items-end absolute -right-2 top-4 ${
                  isUsingJp ? 'right-unit' : '-right-2'
                }`}
              >
                <span className="text-10px">{t('秒')}</span>
              </div>
            </div>
          </>
        )
        break
    }

    return <div className="form-edit-table-test flex">{form}</div>
  }
  const setListId = (id: string) => {
    const dataIds = listIds
    if (!dataIds.includes(id)) {
      dataIds.push(id)
      setListIds(dataIds)
      setFormState('change')
    }
  }
  const dataFormItem = (
    dataIndex: string,
    id: string,
    isDisabled: boolean | undefined,
  ) => {
    const rules = getInputRulesForTestRes(t)[dataIndex]
    const inputNode = (
      <InputNumber disabled={isDisabled} onFocus={() => setListId(id)} />
    )

    return (
      <Form.Item
        name={`${dataIndex}_${id}`}
        style={{ marginTop: 8, width: 120 }}
        rules={rules}
        validateTrigger={['onSubmit', 'onChange']}
      >
        {inputNode}
      </Form.Item>
    )
  }

  const [formEdit] = Form.useForm()
  const [listGrades, setListGrades] = useState<number[]>([])
  const [listClasses, setListClasses] = useState<number[]>([])

  const getFilterData = async () => {
    const apiUrl = `/alpha/v1/school/filterStudents?testYear=${currentYear}`

    const { data: dataFilter } = await Api.get(apiUrl)
    setListGrades(dataFilter?.data?.grades)

    setSchoolGrade(
      dataFilter?.data?.grades?.length ? dataFilter?.data?.grades[0] : 0,
    )
    setListClasses(dataFilter?.data?.classes)
    setSchoolClass(
      dataFilter?.data?.classes?.length ? dataFilter?.data?.classes[0] : 0,
    )

    setIsReadyToCallGetStudents(true)
  }

  useEffect(() => {
    if (filteredStudents?.length) {
      const formEditSave = setFormAll(filteredStudents, currentYear)
      formEdit.setFieldsValue(formEditSave)
    }
  }, [filteredStudents, formEdit])

  useEffect(() => {
    getFilterData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const weightHeightTabTitleDefaultWidth = isUsingJp ? 'w-12' : 'w-16'

  return (
    <Dashboard selectedMenu={3} navbar={t('体力テスト')}>
      {
        <Row className="w-full justify-center">
          <Col className="mt-6" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="flex justify-between">
              {/* filter for table */}
              <div
                className={`space-x-2 pb-2 print:hidden mt-14 ${
                  isUsingJp
                    ? 'test-filter-group-btn-ja'
                    : 'test-filter-group-btn-en'
                }`}
              >
                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={schoolGrade}
                  onChange={onChangeGrade}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="grade-default" value={0}>
                    {t('全学年')}
                  </Option>
                  <>
                    {listGrades.map((v) => (
                      <Option value={v} key={v}>
                        {t('個別学年', {
                          count: v,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    ))}
                  </>
                </Select>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={schoolClass}
                  onChange={onChangeClass}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="class-default" value={0}>
                    {t('全組')}
                  </Option>
                  <>
                    {listClasses.map((_class) => {
                      return (
                        <Option key={`class-${_class}`} value={_class}>
                          {t('個別組', {
                            count: _class,
                            ordinal: !isUsingJp,
                          })}
                        </Option>
                      )
                    })}
                  </>
                </Select>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={filterGender}
                  onChange={onChangeGender}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="gender-0" value="">
                    {t('全性別')}
                  </Option>
                  <Option key="gender-male" value="MALE">
                    {t('男性')}
                  </Option>
                  <Option key="gender-female" value="FEMALE">
                    {t('女性')}
                  </Option>
                </Select>
              </div>

              {/* Notice */}
              <div className="test-header mx-1">
                <div
                  className={`test-header-title ${
                    isUsingJp ? 'text-xl' : 'text-base'
                  }`}
                >
                  {t(
                    '記録を直接入力した場合は、必ず画面右上の「登録」ボタンを押し、登録を行ってください。',
                  )}
                </div>

                <div
                  className={`test-header-description${
                    isUsingJp ? 'text-base' : 'text-sm'
                  }`}
                >
                  {t(
                    '※「登録」を行わなかった場合、直接入力した数値は消えてしまいますのでご注意ください。',
                  )}
                </div>
              </div>

              {/* right buttons */}
              <Button
                type="primary"
                className={`font-black test-register-btn m-3 ${
                  isUsingJp ? 'test-register-btn-ja' : 'test-register-btn-en'
                }`}
                loading={reloading}
                onClick={saveAll}
                disabled={loadingStudent}
              >
                {t('登録')}
              </Button>
            </div>

            {/* statistic at the top of table */}
            <div className="text-10px font-black">
              {t('生徒登録人数', {
                student: isElementarySchool ? '児童' : '生徒',
              })}
              : {total}
            </div>
            <div className="text-10px font-black">
              {t('入力済み人数')}: {totalCompletion}
            </div>
            <div className="text-10px font-black pb-2">
              {t('未登録人数')}: {total - totalCompletion}
            </div>
            <Form form={formEdit} component={false}>
              <Table
                columns={tableColumns as ColumnsType<object>}
                tableLayout="fixed"
                dataSource={filteredStudents}
                loading={{
                  spinning: loadingStudent || reloading,
                  indicator: (
                    <CircularProgressLoading percent={loadingPercent} />
                  ),
                }}
                rowKey="_id"
                size="small"
                style={{ minWidth: 940 }}
                scroll={{ x: 1500, y: '70vh' }}
                pagination={{
                  pageSize: pageSize,
                  defaultPageSize: defaultPageSize,
                  position: ['bottomCenter'],
                  pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
                  showSizeChanger: true,
                  total,
                  current: currentPage,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page)
                    setPageSize(pageSize)
                  },
                }}
                bordered={true}
              />
            </Form>
          </Col>
        </Row>
      }

      {selectedStudent && showDetailModal && (
        <Modal key={`q-${selectedStudent?._id ?? ''}`} {...modalProps}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            hideRequiredMark={true}
          >
            <Row>
              <Col span={12} className="pr-2">
                {getInputList()
                  .slice(0, 4)
                  .map((input) => renderInput(input as unknown as InputConfig))}
              </Col>

              <Col span={12} className="pl-2">
                <Row className="space-x-2">
                  <Col span={6}>
                    <Row>
                      <div
                        className={`h-5 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black ${weightHeightTabTitleDefaultWidth}`}
                      >
                        {t('身長')}
                      </div>
                    </Row>

                    <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                      <Col key={`height-${selectedStudent._id}`} span={6}>
                        <div className="flex relative">
                          <Form.Item
                            className="mb-0-f "
                            name="height"
                            rules={heightRules(t)}
                            validateTrigger={['onSubmit', 'onChange']}
                          >
                            <InputNumber
                              size="small"
                              type="number"
                              inputMode="decimal"
                              step="any"
                              parser={parser}
                            />
                          </Form.Item>
                          <div className="flex items-end absolute left-24 top-4">
                            <span className="text-10px">cm</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={6}>
                    <Row>
                      <div
                        className={`h-5 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black ${weightHeightTabTitleDefaultWidth}`}
                      >
                        {t('体重')}
                      </div>
                    </Row>
                    <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                      <Col key={`weight-${selectedStudent._id}`} span={6}>
                        <div className="flex relative">
                          <Form.Item
                            className="mb-0-f "
                            name="weight"
                            rules={weightRules(t)}
                            validateTrigger={['onSubmit', 'onChange']}
                          >
                            <InputNumber
                              size="small"
                              type="number"
                              inputMode="decimal"
                              step="any"
                              parser={parser}
                            />
                          </Form.Item>
                          <div className="flex items-end absolute left-24 top-4">
                            <span className="text-10px">kg</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {getInputList()
                  .slice(4)
                  .map((input) => renderInput(input as unknown as InputConfig))}
              </Col>
            </Row>
            <Row justify="center">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-60"
                  size="large"
                  loading={loading}
                >
                  {t('登録')}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      )}
    </Dashboard>
  )
}

export default TestInputPage
